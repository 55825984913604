footer {
    color: #fff;
    background-color: #333333;
}

footer h3::after {
    content: '';
    display: block;
    width: 60px;
    margin-top: 6px;
    border-top: 2px solid #fff;
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: rgb(255, 255, 255);
}