footer {
  color: #fff;
  background-color: #333;
}

footer h3:after {
  content: "";
  width: 60px;
  border-top: 2px solid #fff;
  margin-top: 6px;
  display: block;
}

/*# sourceMappingURL=index-fr.2fdca1d9.css.map */
